import React from "react"
import "./personalInformation.css"

export default function PersonalInformation({ info, value, id, email }) {
  return (
    <div id={id} className="personal-info">
      <div className="info">{info}</div>
      {email ? (
        <div className="value value-email">
          <a href="mailto:FSDeryol@gmail.com" target="_blank">
            {value}
          </a>
        </div>
      ) : (
        <div className="value">{value}</div>
      )}
    </div>
  )
}
