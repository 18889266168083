import React, { useState, useEffect } from "react"
import { GrInstagram } from "react-icons/gr"
import { GrTwitter } from "react-icons/gr"
import { GrGithub } from "react-icons/gr"
import { GrMail } from "react-icons/gr"
import { FaLinkedin } from "react-icons/fa"
import SectionHeader from "../../components/commonComponents/SectionHeader/SectionHeader"
import SecondaryHeader from "../../components/commonComponents/SecondaryHeader/SecondaryHeader"
import PersonalInformation from "../../components/aboutMeComponents/PersonalInformation/PersonalInformation"
import Cube from "../../components/Cube/Cube"
import ProjectGrid from "../../components/projectsComponents/ProjectsGrid/ProjectGrid"
import { ReactComponent as DramaClub } from "../../components/svg/drama-club.svg"
import { ReactComponent as BlueVosvos } from "../../components/svg/blue-vosvos.svg"
import SkillsList from "../../components/resumeComponents/SkillsList/SkillsList"
import LiveProject from "../../components/resumeComponents/LiveProject/LiveProject"
import { useInView } from "react-intersection-observer"
import SocialMediaLink from "../../components/navbarComponents/SocialMediaLink/SocialMediaLink"
import Navbar from "../../components/navbarComponents/Navbar/Navbar"
import { useTranslation } from "react-i18next"
import "./homepage.css"

export default function App() {
  const [currentSection, setCurrentSection] = useState("home")
  const [language, setLanguage] = useState("en")
  const [homeSectionAnimations, setHomeSectionAnimations] = useState(false)
  const [aboutSectionAnimations, setAboutSectionAnimations] = useState(false)
  const [resumeSectionAnimation, setResumeSectionAnimation] = useState(false)
  const [projectsSectionAnimation, setProjectsSectionAnimations] =
    useState(false)
  const [contactSectionAnimations, setContactSectionAnimations] =
    useState(false)

  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  useEffect(() => {
    changeLanguage(language)
  }, [language])

  const { ref: homeRef, inView: homeVisible } = useInView({
    triggerOnce: false,
    rootMargin: "-20%",
  })

  const { ref: aboutRef, inView: aboutVisible } = useInView({
    triggerOnce: false,
    rootMargin: "-20%",
  })

  const { ref: resumeRef, inView: resumeVisible } = useInView({
    triggerOnce: false,
    rootMargin: "-40%",
  })

  const { ref: myProjectsRef, inView: myProjectsVisible } = useInView({
    triggerOnce: false,
    rootMargin: "-20%",
  })

  const { ref: contactRef, inView: contactVisible } = useInView({
    triggerOnce: false,
    rootMargin: "-20%",
  })

  const personalInfo = [
    {
      info: t("aboutSection.personalInformationList.name.header"),
      value: t("aboutSection.personalInformationList.name.value"),
    },
    {
      info: t("aboutSection.personalInformationList.age.header"),
      value: t("aboutSection.personalInformationList.age.value"),
    },
    {
      info: t("aboutSection.personalInformationList.email.header"),
      value: t("aboutSection.personalInformationList.email.value"),
      email: true,
    },
    {
      info: t("aboutSection.personalInformationList.from.header"),
      value: t("aboutSection.personalInformationList.from.value"),
    },
  ]

  const liveProjects = [
    {
      projectClass: "drama-club",
      logo: <DramaClub />,
      link: t("resumeSection.liveProject.drama-club.link"),
      text: t("resumeSection.liveProject.drama-club.value"),
    },
    {
      projectClass: "blue-vosvos",
      logo: <BlueVosvos />,
      link: t("resumeSection.liveProject.blue-vosvos.link"),
      text: t("resumeSection.liveProject.blue-vosvos.value"),
    },
  ]

  const socialMediaLinks = [
    {
      name: "instagram",
      link: "https://www.instagram.com/furkan_deryol/",
      svg: <GrInstagram className="instagram-svg" />,
    },
    {
      name: "twitter",
      link: "https://twitter.com/FSDeryol",
      svg: <GrTwitter className="twitter-svg" />,
    },
    {
      name: "github",
      link: "https://github.com/Plac1dusax",
      svg: <GrGithub className="github-svg" />,
    },
    {
      name: "mail",
      link: "mailto:FSDeryol@gmail.com",
      svg: <GrMail className="mail-svg" />,
    },
    {
      name: "linkedin",
      link: "https://www.linkedin.com/in/furkan-%C5%9Fahan-deryol-943074201/",
      svg: <FaLinkedin className="linkedin-svg" />,
    },
  ]

  useEffect(() => {
    if (homeVisible) setHomeSectionAnimations(true)
    if (aboutVisible) setAboutSectionAnimations(true)
    if (resumeVisible) setResumeSectionAnimation(true)
    if (myProjectsVisible) setProjectsSectionAnimations(true)
    if (contactVisible) setContactSectionAnimations(true)
  }, [
    homeVisible,
    aboutVisible,
    resumeVisible,
    myProjectsVisible,
    contactVisible,
  ])

  function handlePageScroll(e) {
    if (homeVisible) setCurrentSection("home")
    if (aboutVisible) setCurrentSection("about me")
    if (resumeVisible) setCurrentSection("resume")
    if (myProjectsVisible) setCurrentSection("projects")
    if (contactVisible) setCurrentSection("contact")
  }

  return (
    <div onScroll={handlePageScroll} className="page-content-wrapper">
      <section
        ref={homeRef}
        id="welcome-section"
        className="section welcome-section"
      >
        <header className="welcome-section-header">
          <SectionHeader
            welcomeSection={true}
            header={t("welcomeSection.headers.mainHeader")}
          />
          <SecondaryHeader
            welcomeSection={true}
            header={t("welcomeSection.headers.secondaryHeader")}
          />
        </header>
        <img
          className="welcome-section-image"
          src={"/images/profile/Furkan.png"}
          alt="picture"
        />
      </section>
      <Navbar
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        setLanguage={setLanguage}
      />
      <section
        id="about-me-section"
        ref={aboutRef}
        className=" section section-dark about-me"
      >
        <SectionHeader
          inView={aboutVisible}
          header={t("aboutSection.headers.sectionHeader")}
        />
        <div className="section-content">
          <div className="about-me">
            <SecondaryHeader
              once={aboutSectionAnimations ? true : false}
              header={t("aboutSection.headers.secondaryHeader")}
              animationActive={aboutVisible ? true : false}
            />
            <p
              className={
                !aboutSectionAnimations
                  ? aboutVisible
                    ? "about-me-text about-me-text-animate"
                    : "about-me-text"
                  : "about-me-text about-me-text-animate"
              }
            >
              {t("aboutSection.textContent")}
            </p>
          </div>
          <div className="personal-info-wrapper">
            {personalInfo.map((information, index) => {
              return (
                <PersonalInformation
                  key={index}
                  id={
                    !aboutSectionAnimations
                      ? aboutVisible
                        ? `personal-info-${index}`
                        : null
                      : `personal-info-${index}`
                  }
                  info={information.info}
                  value={information.value}
                  email={information.email}
                />
              )
            })}
          </div>
        </div>
      </section>
      <section id="resume-section" ref={resumeRef} className="section resume">
        <SectionHeader
          inView={resumeVisible}
          header={t("resumeSection.headers.mainHeader")}
        />
        <div className="skills-wrapper">
          <div className="skills">
            <SecondaryHeader
              once={resumeSectionAnimation ? true : false}
              header={t("resumeSection.headers.secondaryHeader")}
              animationActive={resumeVisible ? true : false}
            />
            <SkillsList
              animationActive={resumeVisible ? true : false}
              resumeSectionAnimation={resumeSectionAnimation}
            />
            <div className="cube-container">
              <div className="skills-cube">
                <Cube />
              </div>
            </div>
          </div>
          <SecondaryHeader header={"My experience"} />
          <div className="live-projects">
            {liveProjects.map((project, index) => {
              return (
                <LiveProject
                  key={index}
                  link={project.link}
                  logo={project.logo}
                  projectClass={project.projectClass}
                  text={project.text}
                  animationActive={resumeVisible ? true : false}
                  resumeSectionAnimation={resumeSectionAnimation}
                />
              )
            })}
          </div>
        </div>
      </section>
      <section
        id="projects-section"
        ref={myProjectsRef}
        className="section section-dark projects"
      >
        <SectionHeader
          inView={myProjectsVisible}
          header={t("projectsSection.headers.mainHeader")}
        />
        <ProjectGrid
          animationActive={myProjectsVisible}
          projectsSectionAnimation={projectsSectionAnimation}
        />
      </section>
      <section
        id="contact-section"
        ref={contactRef}
        className={
          contactSectionAnimations ? "section contact  contact-active" : null
        }
      >
        <SectionHeader
          inView={contactVisible}
          header={t("contactSection.headers.mainHeader")}
        />
        <SecondaryHeader
          once={contactSectionAnimations ? true : false}
          header={t("contactSection.headers.secondaryHeader")}
          animationActive={contactVisible}
        />
        <a className="mail-link" href="mailto:FSDeryol@gmail.com">
          {t("contactSection.mail.mail")}
        </a>
        <div className="contact-links">
          {socialMediaLinks.map((link, index) => {
            return (
              <SocialMediaLink
                key={index}
                link={link.link}
                name={link.name}
                svg={link.svg}
                contact={true}
                animationActive={contactVisible}
                contactSectionAnimations={contactSectionAnimations}
              />
            )
          })}
        </div>
      </section>
    </div>
  )
}
