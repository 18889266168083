import React from "react"
import SkillsListItem from "../SkillsListItem/SkillsListItem"
import { SiJavascript } from "react-icons/si"
import { SiReact } from "react-icons/si"
import { SiCss3 } from "react-icons/si"
import { SiHtml5 } from "react-icons/si"
import { SiNetlify } from "react-icons/si"
import { BsGit } from "react-icons/bs"
import { useTranslation } from "react-i18next"
import "./skillsList.css"

export default function SkillsList({
  animationActive,
  resumeSectionAnimation,
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "resumeSection.skillsList",
  })

  const skillsListItem = [
    {
      listItem: t("html.header"),
      className: "list-item-html",
      listText: t("html.value"),
      logo: <SiHtml5 />,
    },
    {
      listItem: t("css.header"),
      className: "list-item-css",
      listText: t("css.value"),
      logo: <SiCss3 />,
    },
    {
      listItem: t("javascript.header"),
      className: "list-item-javascript",
      listText: t("javascript.value"),
      logo: <SiJavascript />,
    },
    {
      listItem: t("react.header"),
      className: "list-item-react",
      listText: t("react.value"),
      logo: <SiReact />,
    },
    {
      listItem: t("git.header"),
      className: "list-item-git",
      listText: t("git.value"),
      logo: <BsGit />,
    },
    {
      listItem: t("netlify.header"),
      className: "list-item-netlify",
      listText: t("netlify.value"),
      logo: <SiNetlify />,
    },
  ]

  return (
    <ul className="skills-list">
      {skillsListItem.map((listItem, index) => {
        return (
          <SkillsListItem
            key={index}
            listItem={listItem.listItem}
            itemClass={listItem.className}
            logo={listItem.logo}
            listText={listItem.listText}
            animationActive={animationActive}
            resumeSectionAnimation={resumeSectionAnimation}
          />
        )
      })}
    </ul>
  )
}
