import React, { useEffect, useRef } from "react"
import "./sectionHeader.css"

export default function SectionHeader({ welcomeSection, header, inView }) {
  const letters = "ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ"
  const headerRef = useRef()

  useEffect(() => {
    const sectionHeaders = [...document.querySelectorAll(".section-header")]
    if (inView) {
      sectionHeaders.map((header) => {
        if (header.getAttribute("data-active") === "true") return
        if (header === headerRef.current) {
          let interval = null
          let iteration = 0

          clearInterval(interval)

          interval = setInterval(() => {
            header.innerText = header.innerText
              .split("")
              .map((letter, index) => {
                if (index < iteration) {
                  return header.dataset.value[index]
                }

                return letters[Math.floor(Math.random() * 29)]
              })
              .join("")

            if (iteration >= header.dataset.value.length) {
              clearInterval(interval)
            }

            header.dataset.active = "true"
            iteration += 1 / 3
          }, 30)
        }
      })
    }
  }, [inView])

  return welcomeSection ? (
    <h1 className="section-header section-header-welcome"> {header}</h1>
  ) : (
    <h1
      data-active="false"
      data-value={header}
      ref={headerRef}
      className="section-header"
    >
      {header}
    </h1>
  )
}
