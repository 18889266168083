import React, { useState } from "react"
import Flag from "react-world-flags"
import "./selectLanguage.css"

export default function SelectLanguage({ setLanguage }) {
  const [check, setCheck] = useState(false)

  function handleLanguageChange(e) {
    const container = e.target
    const inputElement = container.querySelector(".select-language")

    if (e.target.classList.contains("flag")) return

    setCheck(!check)

    if (!check) {
      inputElement.checked = false
      setLanguage("tr")
    } else {
      inputElement.checked = true
      setLanguage("en")
    }
  }

  return (
    <div className="select-language-container">
      <div
        onClick={handleLanguageChange}
        className={check ? "language language-checked" : "language"}
      >
        <div className="english">
          <Flag className="flag flag-en" code={"GB"} alt={"British flag"} />
        </div>
        <input
          className="select-language"
          type="checkbox"
          id="language"
          name="language"
        />
        <div className="turkish">
          <Flag className="flag flag-tr" code={"TR"} alt={"Turkish flag"} />
        </div>
      </div>
    </div>
  )
}
