import React from "react"
import Project from "../Project/Project"
import "./projectGrid.css"

export default function ProjectGrid({
  animationActive,
  projectsSectionAnimation,
}) {
  const images = [
    {
      name: "image-slider",
      image: "/images/projectImages/image-slider.png",
      liveServerLink: "https://image-slider-55.netlify.app/",
      githubLink: "https://github.com/Plac1dusax/Image-Slider",
    },
    {
      name: "parallax-scroll",
      image: "/images/projectImages/parallax-scroll.png",
      liveServerLink: "https://parallax-scrolling-effect-39.netlify.app/",
      githubLink: "https://github.com/Plac1dusax/Parallax-Scrolling-Effect",
    },
    {
      name: "tic-tac-toe",
      image: "/images/projectImages/tic-tac-toe.png",
      liveServerLink: "https://tic-tac-toe-game-71.netlify.app/",
      githubLink: "https://github.com/Plac1dusax/Tic-Tac-Toe-Game",
    },
    {
      name: "whatsapp-clone",
      image: "/images/projectImages/whatsapp-clone.png",
      liveServerLink: "https://whatsapp-clone-16.netlify.app/",
      githubLink: "https://github.com/Plac1dusax/WhatsApp-Clone",
    },
  ]

  return (
    <div className="projects-grid">
      {images.map((image, index) => {
        return (
          <Project
            key={index}
            name={image.name}
            image={image.image}
            liveServerLink={image.liveServerLink}
            githubLink={image.githubLink}
            animationActive={animationActive}
            projectsSectionAnimation={projectsSectionAnimation}
          />
        )
      })}
    </div>
  )
}
