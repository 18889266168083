import React from "react"
import "./liveProject.css"

export default function LiveProject(props) {
  const {
    link,
    logo,
    projectClass,
    text,
    animationActive,
    resumeSectionAnimation,
  } = props

  return (
    <div className={`live-project ${projectClass}`}>
      <a
        className={
          resumeSectionAnimation
            ? "live-project-logo live-project-logo-active"
            : animationActive
            ? "live-project-logo live-project-logo-active"
            : `live-project-logo live`
        }
        href={link}
        target="_blank"
      >
        {logo}
      </a>
      <div
        className={
          resumeSectionAnimation
            ? `live-project-text live-project-text-${projectClass} live-project-text-${projectClass}-active`
            : animationActive
            ? `live-project-text live-project-text-${projectClass} live-project-text-${projectClass}-active`
            : `live-project-text live-project-text-${projectClass}`
        }
      >
        {text}
      </div>
    </div>
  )
}
