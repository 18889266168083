import React from "react"
import "./secondaryHeader.css"

export default function SecondaryHeader({
  header,
  welcomeSection,
  animationActive,
  once,
}) {
  return welcomeSection ? (
    <h3 className="secondary-header header-animate">{header}</h3>
  ) : (
    <h3
      className={
        once
          ? "secondary-header secondary-header-active"
          : animationActive
          ? "secondary-header secondary-header-active"
          : "secondary-header"
      }
    >
      {header}
    </h3>
  )
}
