import React from "react"
import { FaEye } from "react-icons/fa"
import { GrGithub } from "react-icons/gr"
import { useTranslation } from "react-i18next"
import "./project.css"

export default function Project(props) {
  const {
    name,
    image,
    liveServerLink,
    githubLink,
    animationActive,
    projectsSectionAnimation,
  } = props

  const { t, i18n } = useTranslation()

  return (
    <div
      className={
        projectsSectionAnimation
          ? `project-image-container project-image-container-${name} project-image-container-${name}-active`
          : animationActive
          ? `project-image-container project-image-container-${name} project-image-container-${name}-active`
          : `project-image-container project-image-container-${name}`
      }
    >
      <div className="project-image-wrapper">
        <img className="project-image" src={image} alt="project image" />
      </div>
      <div className="project-image-buttons">
        <div className="image-button-wrapper">
          <a href={liveServerLink} target="_blank">
            <div className="check-live-server">
              <button className="image-button">
                <FaEye />
              </button>
              <div className="image-button-text">
                {t("projectsSection.buttonText.liveServer")}
              </div>
            </div>
          </a>
        </div>
        <div className="image-button-wrapper">
          <a href={githubLink} target="_blank">
            <div className="check-source-code">
              <button className="image-button">
                <GrGithub />
              </button>
              <div className="image-button-text">
                {t("projectsSection.buttonText.sourceCode")}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
