import React, { useEffect } from "react"
import SelectLanguage from "../SelectLanguage/SelectLanguage"
import { useTranslation } from "react-i18next"
import "./navbar.css"

export default function Navbar({
  currentSection,
  setCurrentSection,
  setLanguage,
}) {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    document.addEventListener("click", handleNavClick)

    function handleNavClick(e) {
      const buttonName = e.target.id

      switch (buttonName) {
        case "home":
          document.querySelector(".page-content-wrapper").scrollTo(0, 0)
          setCurrentSection("home")
          break
        case "about":
          document.querySelector("#about-me-section").scrollIntoView()
          setCurrentSection("about me")
          break
        case "resume":
          document.querySelector("#resume-section").scrollIntoView()
          setCurrentSection("resume")
          break
        case "projects":
          document.querySelector("#projects-section").scrollIntoView()
          setCurrentSection("projects")
          break
        case "contact":
          document.querySelector("#contact-section").scrollIntoView()
          setCurrentSection("contact")
          break
      }
    }

    return () => {
      document.removeEventListener("click", handleNavClick)
    }
  }, [])

  return (
    <nav className="navigation">
      <ul>
        <li
          id="home"
          className={
            currentSection === "home"
              ? "navigate navigate-selected"
              : "navigate"
          }
        >
          {t("navigation.home")}
        </li>
        <li
          id="about"
          className={
            currentSection === "about me"
              ? "navigate navigate-selected"
              : "navigate"
          }
        >
          {t("navigation.about")}
        </li>
        <li
          id="resume"
          className={
            currentSection === "resume"
              ? "navigate navigate-selected"
              : "navigate"
          }
        >
          {t("navigation.resume")}
        </li>
        <li
          id="projects"
          className={
            currentSection === "projects"
              ? "navigate navigate-selected"
              : "navigate"
          }
        >
          {t("navigation.projects")}
        </li>
        <li
          id="contact"
          className={
            currentSection === "contact"
              ? "navigate navigate-selected"
              : "navigate"
          }
        >
          {t("navigation.contact")}
        </li>
      </ul>
      <SelectLanguage setLanguage={setLanguage} />
    </nav>
  )
}
