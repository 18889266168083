import React from "react"
import { SiJavascript } from "react-icons/si"
import { SiReact } from "react-icons/si"
import { SiCss3 } from "react-icons/si"
import { SiHtml5 } from "react-icons/si"
import { SiNetlify } from "react-icons/si"
import { BsGit } from "react-icons/bs"
import "./cube.css"

export default function Cube() {
  return (
    <div className="cube-wrapper">
      <div className="cube">
        <div className="side front js">{<SiJavascript />}</div>
        <div className="side left css">{<SiCss3 />}</div>
        <div className="side right netlify">{<SiNetlify />}</div>
        <div className="side back react">{<SiReact />}</div>
        <div className="side top git">{<BsGit />}</div>
        <div className="side bottom html">{<SiHtml5 />}</div>
      </div>
    </div>
  )
}
