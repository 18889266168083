import React from "react"
import "./socialMediaLink.css"

export default function SocialMediaLink(props) {
  const {
    name,
    link,
    svg,
    contact,
    animationActive,
    contactSectionAnimations,
  } = props

  return (
    <div
      className={
        contactSectionAnimations
          ? `${name}-contact ${name}-contact-active`
          : animationActive
          ? contact
            ? `${name}-contact ${name}-contact-active`
            : `${name}`
          : contact
          ? `${name}-contact`
          : `${name}`
      }
    >
      <a href={link} target="_blank">
        {svg}
      </a>
    </div>
  )
}
