import React from "react"
import Homepage from "./page/Homepage/Homepage"
import { Helmet, HelmetProvider } from "react-helmet-async"
import "./App.css"

function App() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Furkan Şahan Deryol</title>
          <meta
            name="description"
            content="Furkan Sahan Deryol's personal website"
          />
          <meta
            name="keywords"
            content="Furkan Şahan Deryol, Furkan Şahan, Furkan Sahan Deryol,Furkan,Sahan,Deryol"
          />
          <meta name="author" content="Plac1dusax" />
        </Helmet>
      </HelmetProvider>
      <Homepage />
    </>
  )
}

export default App
