import React from "react"
import "./skillsListItem.css"

export default function SkillsListItem(props) {
  const {
    listItem,
    itemClass,
    logo,
    listText,
    animationActive,
    resumeSectionAnimation,
  } = props

  return (
    <li className={`skill-list-item ${itemClass}`}>
      <div className="list-item-and-logo-wrapper">
        <div
          className={
            resumeSectionAnimation
              ? "list-item-logo list-item-logo-active"
              : animationActive
              ? "list-item-logo list-item-logo-active"
              : `list-item-logo`
          }
        >
          {logo}
        </div>
        <div
          className={
            resumeSectionAnimation
              ? `list-item ${itemClass}-active`
              : animationActive
              ? `list-item ${itemClass}-active`
              : `list-item`
          }
        >
          {listItem}
        </div>
      </div>
      <div
        className={
          resumeSectionAnimation
            ? `list-item-text ${itemClass}-text-active`
            : animationActive
            ? `list-item-text ${itemClass}-text-active`
            : `list-item-text`
        }
      >
        {listText}
      </div>
    </li>
  )
}
